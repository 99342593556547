import axios from "axios";
import dayjs from "dayjs";

export default ({ generateReportUrl }) => ({
    showGeneratingModal: false,
    generationStatus: "",
    reportLink: "",
    checkReportUrl: null,
    startedGeneratingAt: null,
    get isGenerating() {
        return this.generationStatus === "pending";
    },
    async generateReport() {
        this.showGeneratingModal = true;
        this.generationStatus = "pending";
        try {
            const response = await axios.post(generateReportUrl);
            this.checkReportUrl = response.data.status_url;
            this.startedGeneratingAt = dayjs();
            this.checkStatusIn(5);
        } catch {
            alert("Sorry, that didn't seem to work, try again?");
            this.showGeneratingModal = false;
            this.generationStatus = null;
        }
    },

    statusCheckTimeout: null,
    checkStatusIn(secs) {
        this.statusCheckTimeout = setTimeout(() => {
            this.checkStatus();
        }, secs * 1000);
    },

    async checkStatus() {
        const response = await axios.get(this.checkReportUrl);
        const { status } = response.data;

        if (status === "failed") {
            this.generationStatus = "failed";
            return;
        }

        if (status === "complete") {
            this.generationStatus = "complete";
            const { report_url } = response.data;
            this.reportLink = report_url;
            return;
        }

        // Timeout?
        if (dayjs().diff(this.startedGeneratingAt, "seconds") > 120) {
            console.log(
                "Report Generation Timed Out",
                this.startedGeneratingAt,
                dayjs().diff(this.startedGeneratingAt, "seconds"),
            );
            this.generationStatus = "failed";
            return;
        }

        this.checkStatusIn(3);
    },
});

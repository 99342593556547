import axios from "axios";
export default ({ passwordUpdateUrl }) => ({
    open: false,
    errorMessage: "",
    complete: false,

    init() {
        this.$watch("open", (isOpen) => {
            if (isOpen) {
                document.getElementById("site-container").classList.add("blur");
            } else {
                document.getElementById("site-container").classList.remove("blur");
            }
        });
    },

    async submit(form) {
        try {
            const formData = new FormData(form);
            await axios.post(passwordUpdateUrl, formData);
        } catch (e) {
            if (e.response.status === 422 && e.response.data.message) {
                this.errorMessage = e.response.data.message;
                return;
            }
            alert("Password update failed");
            return;
        }

        this.complete = true;
    },
});

import axios from "axios";

const RESEND_TIMEOUT = 5;

export default ({
    resendCodeUrl,
    verifyCodeUrl,
    profileUpdateUrl,
    currentEmailAddress = "",
    showOnInit = false,
} = {}) => ({
    open: false,
    isLoading: false,
    isSubmittable: false,
    errorMessage: "",
    code: "",
    canResendIn: RESEND_TIMEOUT,
    codeResent: false,
    resendInterval: null,

    changeEmailAddress: false,
    currentEmailAddress,
    newEmailAddress: currentEmailAddress,

    init() {
        this.$watch("open", (isOpen) => {
            if (isOpen) {
                document.getElementById("site-container").classList.add("blur");
            } else {
                document.getElementById("site-container").classList.remove("blur");
            }
        });
        this.$watch("code", (code) => {
            this.isSubmittable = code.length === 6;
        });
        this.$watch("open", (open) => {
            if (open) {
                this.startResendCountdown();
            } else {
                this.stopResendCountdown();
            }
        });

        if (showOnInit) {
            this.open = true;
        }
    },

    resetVerifyStatus() {
        this.errorMessage = "";
        this.code = "";
    },

    async submit() {
        this.isLoading = true;

        try {
            await axios.post(verifyCodeUrl, {
                code: this.code,
            });
            this.open = false;
            this.$dispatch("user-verification-complete", true);
        } catch (e) {
            if (e.response.status === 429) {
                this.errorMessage = "Too many attempts, please wait";
            } else if (e.response.status === 401) {
                this.errorMessage = "Session expired, please restart comparison";
            } else {
                this.errorMessage = "Invalid Code";
            }
        }

        this.isLoading = false;
    },

    startResendCountdown() {
        this.canResendIn = RESEND_TIMEOUT;
        this.codeResent = false;
        this.resendInterval = setInterval(() => {
            this.canResendIn--;
            if (this.canResendIn <= 0) {
                this.stopResendCountdown();
            }
        }, 1000);
    },

    stopResendCountdown() {
        clearInterval(this.resendInterval);
    },

    async resendCode() {
        try {
            await axios.post(resendCodeUrl);
            this.codeResent = true;
            setTimeout(() => {
                this.startResendCountdown();
            }, 5000);
        } catch (e) {
            if (e.response.status ?? null === 401) {
                alert("Session expired - You must restart your comparison");
                window.location.href = "/";
                return;
            }
            alert("Code failed to send, try again maybe?");
        }
    },

    async updateEmailAddress() {
        this.resetVerifyStatus();

        if (this.newEmailAddress === this.currentEmailAddress) {
            this.changeEmailAddress = false;
            return;
        }

        this.isLoading = true;
        try {
            await axios.patch(profileUpdateUrl, { email: this.newEmailAddress });
            await this.resendCode();
            this.changeEmailAddress = false;
            this.currentEmailAddress = this.newEmailAddress;
        } catch (e) {
            if (e.response.data.message) {
                this.errorMessage = e.response.data.message;
            } else {
                this.errorMessage = "Email change failed, try again.";
            }
        }
        this.isLoading = false;
    },
});

//const colors = require("tailwindcss/colors");
import colors from "tailwindcss/colors.js";

import.meta.glob(["../images/**"]);

import axios from "axios";
window.axios = axios;

import ApexCharts from "apexcharts";
window.ApexCharts = ApexCharts;
window.Apex.chart = { fontFamily: "Inter, ui-sans-serif, system-ui, sans-serif" };
window.Apex.colors = ["red", "emerald", "amber", "sky", "fuchsia", "orange", "lime", "rose"].map((c) => colors[c][400]);

// import "./bootstrap";

import Alpine from "alpinejs";

import collapse from "@alpinejs/collapse";
import anchor from "@alpinejs/anchor";
import Tooltip from "@ryangjchandler/alpine-tooltip";

//
Alpine.plugin(collapse);
Alpine.plugin(anchor);
Alpine.plugin(
    Tooltip.defaultProps({
        theme: "dark",
    }),
);

import verifyPopup from "./components/verifyPopup.js";
Alpine.data("verifyPopup", verifyPopup);

import passwordPopup from "./components/passwordPopup.js";
Alpine.data("passwordPopup", passwordPopup);

import generateReportPopup from "./components/generateReportPopup.js";
Alpine.data("generateReportPopup", generateReportPopup);

import vendorStats from "./components/vendorStats.js";
Alpine.data("vendorStats", vendorStats);

window.Alpine = Alpine;

import Sortable from "sortablejs";
window.Sortable = Sortable;

import Swiper from "swiper";
import { Pagination, Autoplay, Navigation } from "swiper/modules";

window.Swiper = Swiper;
window.SwiperModules = { Pagination, Autoplay, Navigation };

import Fuse from "fuse.js";
window.FuseJS = Fuse;

window.addEventListener("load", () => {
    Alpine.start();
});

// Expose the debounce utility for everyone to use
const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            callback(...args);
        }, wait);
    };
};
window.debounce = debounce;
